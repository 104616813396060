<template>
  <q-page class="q-py-md q-px-xl">
    <div class="row q-gutter-xl">
      <q-card class="my-card">
        <q-card-section class="q-py-xs">
          Data Terhimpun
        </q-card-section>
        <q-card-section class="text-h5 text-center text-bold text-white">
          {{ count_survey }}
        </q-card-section>
        <q-card-section class="q-py-xs text-right">
          Orang
        </q-card-section>
      </q-card>
      <q-card class="my-card">
        <q-card-section class="q-py-xs">
          Perlu Karantina
        </q-card-section>
        <q-card-section class="text-h5 text-center text-bold text-white">
          {{ need_karantina }}
        </q-card-section>
        <q-card-section class="q-py-xs text-right">
          Orang
        </q-card-section>
      </q-card>
      <q-card class="my-card">
        <q-card-section class="q-py-xs">
          Perlu Isolasi
        </q-card-section>
        <q-card-section class="text-h5 text-center text-bold text-white">
          {{ need_isolasi }}
        </q-card-section>
        <q-card-section class="q-py-xs text-right">
          Kamar
        </q-card-section>
      </q-card>
    </div>

    <div class="full-width">
      <q-card class=" q-my-md">
        <q-card-section class="row justify-between">
          <q-select
            class="col-4 "
            outlined
            emit-value
            map-options
            dense
            v-model="selectPeriod"
            :options="optionPeriod"
            label="Periode Arsip"
            @input="fetchData(orderBy)"
          />
          <!-- <vue-excel-xlsx
            :disabled="!selectPeriod"
            class="col-auto"
            :data="listData_all"
            :columns="excelColumns"
            :filename="'Arsip Survey COVID ' + selectPeriod"
            :sheetname="'sheet1'"
          >
            Download Arsip
          </vue-excel-xlsx> -->
          <q-btn outline no-caps :disabled="!selectPeriod"  color="indigo-10" label="Download Arsip" @click="downloadArsip" />
        </q-card-section>
      </q-card>
      <div class="row justify-between items-end q-py-sm">
        <div class="row ">
          Periode :
          <div v-if="range_date.from" class="text-grey">
            {{ range_date.from | moment("DD MMMM YYYY") }} s.d {{ range_date.to | moment("DD MMMM YYYY") }}
          </div>
          <div v-else class="text-grey text-italic">-- s.d. --</div>
        </div>
        <q-input
          outlined
          v-model="search"
          placeholder="Cari Nama"
          dense
          class="bg-white"
          style="border-radius: 5px; width: 350px;"
          @keypress.enter="fetchData('nama')"
        >
          <template v-slot:append>
            <q-btn
              flat
              icon="search"
              class="text-white absolute-right"
              @click="fetchData('nama')"
              style="background-color:#03913F"
            />
          </template>
        </q-input>
      </div>
      <div class="row q-pb-md">
        <q-markup-table bordered dense class="col-12">
          <thead class="text-blue-grey-14">
            <tr>
              <th style="width:10px;" @click="fetchData('no_induk')">No Induk</th>
              <th class="text-left" @click="fetchData('nama')">Nama</th>
              <th class="text-left" @click="fetchData('')">Jabatan</th>
              <th class="text-center" @click="fetchData('jenis_kelamin')">Gender</th>
              <th class="text-center" @click="fetchData('no_kamar')">kamar</th>
              <th class="text-right" @click="fetchData('nilai_survey')">Nilai Survey</th>
            </tr>
          </thead>
          <tbody v-if="listData.length" class="">
            <tr v-for="d in listData" :key="d.spp_id">
              <td class="text-left">{{ d.no_induk }}</td>
              <td class="text-left">{{ d.nama }}</td>
              <td class="text-left">
                {{ d.jabatan }}
              </td>
              <td class="text-center" style="width: 100px;">
                {{ d.jenis_kelamin }}
              </td>
              <td class="text-center" style="width: 100px;">
                {{ d.no_kamar }}
              </td>
              <td :class="'text-center ' + (d.karantina == 'Y' ? 'text-negative ' : '')" style="width: 100px;">
                {{ d.nilai_survey }}
              </td>
            </tr>
          </tbody>
          <tbody v-else class="bg-green-1">
            <tr>
              <td class="text-center text-grey" colspan="99">
                tidak ada data
              </td>
            </tr>
          </tbody>
        </q-markup-table>
      </div>
      <div class="row justify-end">
        <q-pagination
          v-model="activePage"
          :max="Math.ceil(count_survey / 10)"
          input
          input-class="text-orange-10"
          @click="fetchData(orderBy)"
        />
      </div>
    </div>
  </q-page>
</template>

<style></style>

<script>
import XLSX from "xlsx/xlsx";

export default {
  name: "Result",
  data() {
    return {
      selectPeriod: null,
      optionPeriod: [],
      listData: [],
      listData_all: [],
      need_karantina: 0,
      need_isolasi: 0,
      count_siswa: 0,
      count_survey: 0,
      orderBy: "",
      orderType: "",
      activePage: 1,
      range_date: {},
      kode_unik: "ARRCVD",
      search: "",
      confirm: false,

      excelColumns: [
        {
          label: "Tanggal",
          field: "tanggal",
          dataFormat: this.dateFormat
        },
        {
          label: "No Induk",
          field: "no_induk",
        },
        {
          label: "Nama",
          field: "nama",
        },
        {
          label: "Jenis Kelamin",
          field: "jenis_kelamin",
        },
        {
          label: "Jabatan",
          field: "jabatan",
        },
        {
          label: "Gedung",
          field: "gedung",
        },
        {
          label: "Kamar",
          field: "no_kamar",
        },
        {
          label: "Nilai Survey",
          field: "nilai_survey",
        },

        {
          label: "Q1",
          field: "Q1",
        },
        {
          label: "Q2",
          field: "Q2",
        },
        {
          label: "Q3",
          field: "Q3",
        },
        {
          label: "Q4",
          field: "Q4",
        },
        {
          label: "Q5",
          field: "Q5",
        },
        {
          label: "Q6",
          field: "Q6",
        },
        {
          label: "Q7",
          field: "Q7",
        },
        {
          label: "Q8",
          field: "Q8",
        },

        {
          label: "Karantina",
          field: "karantina",
        },
        {
          label: "Surveyor",
          field: "surveyor",
        },
      ],
    };
  },
  mounted() {
    this.fetchData("no_induk");
  },
  methods: {
    dateFormat(val) {
      return this.$moment(val).format("DD-MMM-YYYY HH:MM:SS")
    },
    downloadArsip() {
      let columns = this.excelColumns;
      let data = this.listData_all;
      // console.log(data)
      let filename = "Arsip Survey COVID " + this.selectPeriod;
      let sheetname = "Hasil Survey";

      let createXLSLFormatObj = [];
      let createXLSLFormatObj2 = [];
      let createXLSLFormatObj3 = [];
      let newXlsHeader = [];
      let vm = this;
      if (columns.length === 0) {
        console.log("Add columns!");
        return;
      }
      if (data.length === 0) {
        console.log("Add data!");
        return;
      }
      createXLSLFormatObj.push(["Data Terhimpun : ",this.count_survey])
      createXLSLFormatObj.push(["Perlu Karantina : ",this.need_karantina])
      createXLSLFormatObj.push(["Perlu Isolasi : ",this.need_isolasi])
      createXLSLFormatObj.push(["Periode : ",this.$moment(this.range_date.from).format('DD-MMM-YYYY') + ' hingga ' + this.$moment(this.range_date.to).format('DD-MMM-YYYY')])
      columns.forEach((value, index) => {
        newXlsHeader.push(value.label);
      });

      createXLSLFormatObj.push(newXlsHeader);

      data.forEach((value, index) => {
        let innerRowData = [];
        columns.forEach((val, index) => {
          if (val.dataFormat && typeof val.dataFormat === "function") {
            innerRowData.push(val.dataFormat(value[val.field]));
          } else {
            innerRowData.push(value[val.field]);
          }
        });
        createXLSLFormatObj.push(innerRowData);
      });

      let qGuru = [
        {
          no: '1.',
          text: 'Pernah berkunjung ke tempat umum (pasar, fasyankes, kerumunan, dll) dalam 7 hari terakhir? (10 poin)'
        },
        {
          no: '2.',
          text: 'Pernah menggunakan transportasi umum dalam 7 hari terakhir? (10 poin)'
        },
        {
          no: '3.',
          text: 'Pernah melakukan perjalanan luar kota/internasional(zona merah) dalam 7 hari terakhir? (10 poin)'
        },
        {
          no: '4.',
          text: 'Pernah mengikuti kegiatan yang melibatkan orang banyak dalam 7 hari terakhir? (10 poin)'
        },
        {
          no: '5.',
          text: 'Pernah kontak erat dengan ODP, PDP atau konfirm COVID-19(jabat tangan, berbicara, berada dalam 1 ruangan) dalam 7 hari terakhir? (50 poin)'
        },
        {
          no: '6.',
          text: 'Pernah mengalami minimal salah satu dari demam, batuk, pilek, sakit tenggorokan, sesak, hilang penciuman, hilang perasa dalam 7 hari terakhir? (50 poin)'
        },
        {
          no: '7.',
          text: 'Ada orang serumah yang berinteraksi dengan ODP, PDP atau konfirm COVID-19 dalam 7 hari terakhir? (50 poin)'
        },
        {
          no: '8.',
          text: 'Tidak menggunakan masker saat keluar rumah? (10 poin)'
        }
      ]
      let qSiswa = [
        {
          no: '1.',
          text: 'Mengalami gejala Flu,Demam dan Batuk dalam 7 hari terakhir? (10 poin)'
        },
        {
          no: '2.',
          text: 'Mengalami diare dalam 7 hari terakhir? (10 poin)'
        },
        {
          no: '3.',
          text: 'Mengalami kehilangan fungsi indra penciuam, dan indra perasa dalam 7 hari terakhir? (10 poin)'
        },
        {
          no: '4.',
          text: 'Pernah tidak menggunakan masker saat keluar Asrama, ataupun ketika dalam melaksanakan aktifitas? (10 poin)'
        },
        {
          no: '5.',
          text: 'Mengalami demam lebih dari 37.2&#176;C dalam 3 hari terakhir? (10 poin)'
        },
      ]

      qGuru.forEach((value, index) => {
        let innerRowData = [];
        innerRowData.push(value['no'])
        innerRowData.push(value['text'])
        createXLSLFormatObj2.push(innerRowData);
      });
      qSiswa.forEach((value, index) => {
        let innerRowData = [];
        innerRowData.push(value['no'])
        innerRowData.push(value['text'])
        createXLSLFormatObj3.push(innerRowData);
      });

      filename = filename + ".xlsx";
      let ws_name = sheetname;
      let wb = XLSX.utils.book_new(),
        ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj),
        ws2 = XLSX.utils.aoa_to_sheet(createXLSLFormatObj2),
        ws3 = XLSX.utils.aoa_to_sheet(createXLSLFormatObj3)
        ;
      XLSX.utils.book_append_sheet(wb, ws, ws_name);
      XLSX.utils.book_append_sheet(wb, ws2, 'Daftar Pertanyaan Guru');
      XLSX.utils.book_append_sheet(wb, ws3, 'Daftar Pertanyaan Siswa');
      XLSX.writeFile(wb, filename);
    },
    fetchData(order) {
      this.orderBy = "id";
      this.orderType = "DESC";

      var src = "%25";
      if (this.search != "") src = this.search;

      this.$http
        .get(
          "/arsip_list_survey/" +
            src +
            "/" +
            this.selectPeriod +
            "/" +
            this.orderBy +
            "/" +
            this.orderType +
            "/" +
            (this.activePage - 1) * 10,
          {}
        )
        .then((result) => {
          this.listData = result.data;
        });
      this.$http
        .get("/arsip_list_survey_all/" + src + "/" + this.selectPeriod + "/" + this.orderBy + "/" + this.orderType, {})
        .then((result) => {
          this.listData_all = [];
          // this.listData_all.push({});
          this.listData_all = result.data;
          // console.log(this.listData_all)
          this.listData_all.forEach((val,index) => {
            // console.log(val)
            if(val.jabatan == 'siswa') {
              val.Q6 = '-'
              val.Q7 = '-'
              val.Q8 = '-'
            }
          })
        });
      this.$http.get("/jml_siswa", {}).then((result) => {
        this.count_siswa = result.data.count;
      });
      this.$http.get("/jml_survey/" + src + "/" + this.selectPeriod, {}).then((result) => {
        this.count_survey = result.data.count;
      });
      this.$http.get("/perlu_karantina" + "/" + this.selectPeriod, {}).then((result) => {
        this.need_karantina = result.data.count;
      });
      this.$http.get("/perlu_isolasi" + "/" + this.selectPeriod, {}).then((result) => {
        this.need_isolasi = result.data.count;
      });
      this.$http.get("/range_date" + "/" + this.selectPeriod, {}).then((result) => {
        this.range_date = result.data;
      });
      this.$http.get("/periode_arsip", {}).then((result) => {
        this.optionPeriod = [];
        result.data.forEach((element) => {
          var formatedLabel = element.tgl_arsip
            .split("-")
            .reverse()
            .join("-");
          var temp = {
            value: element.tgl_arsip,
            label: formatedLabel,
          };
          this.optionPeriod.push(temp);
        });
        // console.log(this.optionPeriod);
      });
    },
    arsipkan() {
      this.$http.put("/arsipkan", {}).then((result) => {
        //Ga jadi dipake
        // let rand = Math.random().toString(36).substring(6).toUpperCase();
        // this.$http.post('/token/' + rand, {})
        // .then (result => {
        // })

        this.fetchData("nama");
        this.confirm = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.my-card {
  // min-width: 250px;
  flex-grow: 2;
  // background-color: #B9F6CA;
  background-color: #232979;
  color: #babef2;
}
.q-table--dense .q-table tbody tr,
.q-table--dense .q-table tbody td {
  height: 36px;
}
</style>
